/**
 * The main javaScript file. This is where the configuration of the application starts and global properties and styles are defined or imported.
 * With keycloak integrated the application will get a token first, before the user is able to enter the app.
 * Then the token will be automatically applied to each request in the session.
 *
 * @property {String} KEYCLOAK_URL
 * @property {String} KEYCLOAK_CLIENTID
 * @property {String} KEYCLOAK_REALM
 * @property {Number} TOKEN_REFRESH_INTERVAL
 * @property {String} BASEFLOW_BACKEND_ENDPOINT
 * @property {String} EDITOR_URL
 * @property {String} WWA_URL
 **/
import App from '@/App.vue'
import NoAccess from '@/NoAccess.vue'
import '@/registerServiceWorker'
import router from '@/router'

// import styles
import '@/assets/styles/colors.less'
import '@/assets/styles/dimensions.less'
import '@/assets/styles/fonts.less'
import '@/assets/styles/generals.less'
import '@/assets/styles/sweetalert.less'
import '@/assets/styles/transitions.less'
import '@/assets/fonts/FontAwesome/less/fontawesome.less'
import '@/assets/fonts/FontAwesome/less/solid.less'
import '@/assets/fonts/FontAwesome/less/regular.less'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

// import external libraries
import { i18n } from '@/i18n/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Keycloak from 'keycloak-js'
import getRuntimeConfig from '@/runtime-config'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VirtualScroller from 'vue-virtual-scroller'
import { createApp } from 'vue'
import useGlobalProperties from '@/mixins/useGlobalProperties'

function commonUses (app) {
    app.use(i18n)
    app.use(VueAxios, axios)
    app.use(VueSweetalert2)
    app.use(VueDOMPurifyHTML, {
        namedConfigurations: {
            USE_PROFILES: { html: true }
        },
        /* Add hook to secure external links and re-enable opening them in a new tab
         * https://github.com/cure53/DOMPurify/issues/317#issuecomment-698800327
         * https://www.markus-steinacher.at/sichere-externe-links-durch-target_blank-relnoopener-noreferrer/
         */
        hooks: {
            afterSanitizeAttributes: (node) => {
                if ('target' in node) {
                    node.setAttribute('target', '_blank')
                    node.setAttribute('rel', 'noopener noreferrer')
                }
            }
        }
    })
    return app
}

function createInitialApp (globalProperties) {
    const app = createApp(App)
    commonUses(app)
        .use(router)
        .use(VirtualScroller)

    app.config.globalProperties.$global = globalProperties
    app.config.globalProperties.$tc = i18n.global.tc
    app.config.globalProperties.$t = i18n.global.t
    app.config.globalProperties.$d = i18n.global.d
    return app
}

getRuntimeConfig()
    .then(config => {
        const keycloak = new Keycloak({
            url: config.KEYCLOAK_URL,
            realm: config.KEYCLOAK_REALM,
            clientId: config.KEYCLOAK_CLIENTID
        })
        keycloak
            .init({ onLoad: 'login-required', checkLoginIframe: false, pkceMethod: 'S256' })
            .then(auth => {
                if (auth && keycloak.realmAccess.roles.includes('user')) {
                    setBearerTokenInAxiosConfiguration()
                    setTokenRefreshInterval(config.TOKEN_REFRESH_INTERVAL || 3)
                    const globalProperties = useGlobalProperties(config, keycloak)
                    const app = createInitialApp(globalProperties.globals)
                    app.mount('#app')
                } else {
                    throw new DOMException('No access')
                }
            }).catch(() => {
                const app = createApp(NoAccess)
                commonUses(app).mount('#app')
            })

        function setBearerTokenInAxiosConfiguration () {
            axios.interceptors.request.use(config => {
                config.headers.Authorization = `Bearer ${keycloak.token}`
                return config
            }, error => {
                return Promise.reject(error)
            })
        }

        function setTokenRefreshInterval (timeInMinutes) {
            const timeInSeconds = timeInMinutes * 60
            const timeInMilliSeconds = timeInSeconds * 1000

            setInterval(() => {
                keycloak.updateToken(timeInSeconds)
                    .catch(() => {
                        console.log('Failed to refresh token')
                    })
            }, timeInMilliSeconds)
        }
    })
